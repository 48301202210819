var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "match-height"
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('badge-contextual')], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('badge-glow')], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('badge-light')], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('badge-icon')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('badge-link')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('badge-block')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }