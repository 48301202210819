var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Badges With Icons"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeIcon) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_vm._v(" You can add icon inside badge components. ")]), _c('div', {
    staticClass: "demo-inline-spacing"
  }, [_c('b-badge', {
    attrs: {
      "variant": "primary"
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "StarIcon"
    }
  }), _c('span', [_vm._v("Primary")])], 1), _c('b-badge', [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "StarIcon"
    }
  }), _c('span', [_vm._v("Secondary")])], 1), _c('b-badge', {
    attrs: {
      "variant": "success"
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "StarIcon"
    }
  }), _c('span', [_vm._v("Success")])], 1), _c('b-badge', {
    attrs: {
      "variant": "danger"
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "StarIcon"
    }
  }), _c('span', [_vm._v("Danger")])], 1), _c('b-badge', {
    attrs: {
      "variant": "warning"
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "StarIcon"
    }
  }), _c('span', [_vm._v("Warning")])], 1), _c('b-badge', {
    attrs: {
      "variant": "info"
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "StarIcon"
    }
  }), _c('span', [_vm._v("Info")])], 1), _c('b-badge', {
    attrs: {
      "variant": "dark"
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "StarIcon"
    }
  }), _c('span', [_vm._v("Dark")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }