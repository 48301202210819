var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Block Badge"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeBlock) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("Use ")]), _c('code', [_vm._v(".d-block")]), _c('span', [_vm._v(" class with ")]), _c('code', [_vm._v("<b-badge>")]), _c('span', [_vm._v(", to display badge as a block element.")])]), _c('div', {
    staticClass: "text-center"
  }, [_c('b-badge', {
    staticClass: "d-block",
    attrs: {
      "href": "#",
      "variant": "primary"
    }
  }, [_vm._v(" Badge ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }